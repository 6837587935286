import { useEffect, useState } from 'react';

export interface PaginationProps<R, V> {
  onLoadMore: (data: R, currentPage: number) => { endCursor?: string; hasNextPage: boolean };
  pageInfo: { endCursor?: string; hasNextPage: boolean };
  query: (ttl: number, variables?: V) => Promise<R>;
  ttl: number;
  variables: V;
}

export default function usePagination<R, V>({ onLoadMore, pageInfo: pageInfoProp, ttl, query, variables }: PaginationProps<R, V>) {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageInfo, setPageInfo] = useState({ ...pageInfoProp, currentPage: 1 });

  useEffect(() => {
    if (!isLoadingMore) return;

    query(ttl, { ...variables, after: pageInfo.endCursor })
      .then(data => {
        const currentPage = pageInfo.currentPage + 1;
        setPageInfo({ ...onLoadMore(data, currentPage), currentPage });
      })
      .finally(() => {
        setIsLoadingMore(false);
      });
  }, [isLoadingMore]);

  function handleLoadMore() {
    if (!pageInfo.hasNextPage || isLoadingMore) return;

    setIsLoadingMore(true);
  }

  return {
    currentPage: pageInfo.currentPage,
    handleLoadMore,
    hasNextPage: pageInfo.hasNextPage
  };
}
