import { Fragment } from 'react';
import { PHOTO_GALLERIES, usePhotoGalleriesContainer } from '..';
import InfiniteScroll from '@/components/InfiniteScroll';
import PhotoGalleryTile from 'components/PhotoGalleryTile';
import { localizeDate } from 'components/LocalizedDate';
import PhotoGalleriesGrid from './PhotoGalleriesGrid';
import styles from './styles/PhotoGalleriesGrid.module.scss';
import Link from 'components/Link';
import MinuteMediaAd, { MinuteMediaPlacementType } from '@/components/ads/MinuteMediaAd';

const adSlots: { [key: number]: MinuteMediaPlacementType } = {
  3: 'IN_FEED',
  6: 'IN_FEED_2'
};
const adIndexes = Object.keys(adSlots).map(i => +i);

export default function PhotoGalleriesInfiniteScroll() {
  const [{ galleries, hasNextPage }, { handleLoadMore }] = usePhotoGalleriesContainer();

  return (
    <>
      {galleries.length > 0 && (
        <InfiniteScroll onEndReached={handleLoadMore} hasNextPage={hasNextPage}>
          <PhotoGalleriesGrid>
            {galleries.map(({ id, coverPhoto, genderSport, teams, date, photoCount, photographer, webPath, thumbnailUrl }, index) => {
              return (
                <Fragment key={id}>
                  {adIndexes.includes(index) && <MinuteMediaAd placement={adSlots[index]} className={styles.AdFullWidth} />}
                  <PhotoGalleryTile
                    coverImage={coverPhoto.watermark}
                    genderSport={genderSport?.key ?? ''}
                    teams={teams}
                    dateText={localizeDate(date, 'MMMM DD, YYYY')}
                    galleryCount={photoCount}
                    linkUrl={webPath}
                    photographer={photographer}
                    thumbnailUrl={thumbnailUrl}
                  />
                </Fragment>
              );
            })}
          </PhotoGalleriesGrid>
        </InfiniteScroll>
      )}
      {galleries.length === 0 && (
        <div className={styles.NoGalleries}>
          No photo galleries, try removing some filters or going to <Link href={`/${PHOTO_GALLERIES}`}>all galleries</Link>.
        </div>
      )}
    </>
  );
}
